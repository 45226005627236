if (document.body.classList.contains('page-id-4094') || document.body.classList.contains('page-id-4123')) {

  moment.locale('it');

  (function(d, s, id){
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {return;}
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  window.fbAsyncInit = function () {
    var userID = "2711666958913832";
    var instagramID = '17841408878927314';
    var accessToken = '';
    var pageAccessToken = '';
    var nextpage= [];
    pageAccessToken = document.getElementById('pagestring').innerHTML;

    FB.init({
      appId: '179699010118578',
      xfbml: true,
      version: 'v6.0',
      cookie: true
    });

    function userAPIRequest() {
      FB.api('/657192731012219?fields=access_token,instagram_business_account,id,name,picture,fan_count,rating_count,overall_star_rating', function (response) {

        if (response.access_token) {
          pageAccessToken = response.access_token;
        } else {
          console.error(response.error.message);
        }

        document.getElementById('accountInfo').innerHTML = (
          '<div id="user-' + response.id + '" class="center social-user-box">' +
          '<img class="avatar" src="' + response.picture.data.url + '">' +
          '<div class="user-data">' +
            '<h3>' + response.name + '</h3>' +
            '<p><span><i class="material-icons">thumb_up</i> Piace a ' + response.fan_count  + ' persone' + '</span>' +
            '<span><i class="material-icons">star</i> ' + response.overall_star_rating + '/5 basato su ' + response.rating_count + ' recensioni</p></span>' +
            '</div>' +
          '</div>'
        );
      });
    }

    const $container = document.getElementById('masonry-wrapper');
    let msnry = new Masonry($container, {
      // Masonry options...
      itemSelector: '.grid__item', // select none at first
      columnWidth: '.grid__col-sizer',
      gutter: '.grid__gutter-sizer',
      percentPosition: true,
      transitionDuration: '0.8s',
      stagger: '0.2s',
      // nicer reveal transition
      visibleStyle: { transform: 'translateY(0)', opacity: 1 },
      hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
    });

    function updateNextURL( next, socialmedia ) {
      var tag = socialmedia + 'navigation';
      var navigation = document.getElementById(tag);
      navigation.innerHTML = '';
      var nextLink = navigation.appendChild(document.createElement('a'));
      nextLink.href = next;

      nextpage[socialmedia] = next;
    }

    function buildFeedHtml(data) {
      let newsHtml = '';

      for (let item of data) {
        let tagHtml = '';
        if (item.type === 'fb') {
          newsHtml += '<article class="grid__item fb-feed-' + item.id +'">';
          tagHtml = 'Facebook Post';
        } else {
          newsHtml += '<article class="grid__item insta-feed-' + item.id +'">';
          tagHtml = 'Instagram Post';
        }

        // full_picture
        if (item.type === 'fb' && item.full_picture) {
          newsHtml += '<div class="photo-wrapper"><a data-fancybox="gallery' + item.id +'" href="' + item.full_picture + '"><img src=" ' + item.full_picture + '" /></a></div>';
        } else if (item.type === 'insta' && item.media_url) {
          newsHtml += '<div class="photo-wrapper"><a data-fancybox="gallery' + item.id +'" href="' + item.media_url + '"><img src=" ' + item.media_url + '" /></a></div>';
        }

        if (item.message_tags != undefined) {
          // message_tags
          tagHtml = '';
          for (let tag of item.message_tags) {
            tagHtml += tag.name + ' ';
          }
        }

        // Post social logo display
        let socialiconHtml = '';
        if (item.type === 'fb') {
          socialiconHtml = '<div class="post-icon"><i class="social-ico facebook primary"></i></div>';
        } else {
          socialiconHtml = '<div class="post-icon"><i class="social-ico instagram primary"></i></div>';
        }

        let permalinkHtml = '';
        if (item.type === 'fb') {
          permalinkHtml = item.permalink_url;
        } else {
          permalinkHtml = item.permalink;
        }

        // attachments
        let attachmentsHtml = '';
        if (item.attachments && item.attachments.data[0].type === 'album') {
          attachmentsHtml += '<p class="fb-gallery">';
          for (let image of item.attachments.data[0].subattachments.data) {
            attachmentsHtml += '<a data-fancybox="gallery' + item.id +'" href="' + image.media.image.src + '"><img class="fb-photo" src="' + image.media.image.src + '" /></a>';
          }
          attachmentsHtml += '</p>';
        }

        // post meta (date, likes, comments)
        let metaHtml = '';
        if (item.type === 'fb') {
          metaHtml = '<p class="fb-meta">' +
            moment(item.updated_time).calendar() +
            '<i class="material-icons">comment</i> ' + item.comments.summary.total_count +
            '<i class="material-icons">thumb_up</i> ' + item.likes.summary.total_count +
            '</p>';
        } else {
          metaHtml = '<p class="fb-meta">' +
            moment(item.updated_time).calendar() +
            '<i class="material-icons">comment</i> ' + item.comments_count +
            '<i class="material-icons">thumb_up</i> ' + item.like_count +
            '</p>';
        }

        // post content
        let postContentHtml = '';
        if (item.type === 'fb' && item.message != undefined) {
          postContentHtml = '<p>' + item.message + '</p>';
        } else if (item.type === 'insta' && item.caption != undefined) {
          postContentHtml = '<p>' + item.caption + '</p>';
        }

        newsHtml +=
          '<div class="article-wrapper">' +
            '<div class="article-col">' +
              socialiconHtml +
              '<div class="fb-content">' +
                '<header class="entry-header">' +
                  '<a href="' + permalinkHtml + '">' +
                  metaHtml +
                  '<h4>' + tagHtml + '</h4>' +
                  '</a>' +
                '</header>' +
                '<div class="entry-content">' +
                  postContentHtml +
                  attachmentsHtml +
                '</div>' +
              '</div>' +
            '</div>'+
          '</div>';
        newsHtml += '</article>';
      }
      return newsHtml;
    }

    function feedsAPIRequest() {

      var rFacebook = '657192731012219/published_posts?fields=id,message,message_tags,full_picture,permalink_url,updated_time,attachments,shares,likes.summary(true).limit(0),comments.summary(true).limit(0)&limit=5';
      var rInsta = '17841408878927314/media?fields=id,caption,timestamp,comments_count,like_count,media_type,media_url,permalink,comments&limit=5';
      var rToken = '&access_token=' + pageAccessToken;
      var req = rFacebook + rToken;

      FB.api( req, function (response) {

        var instaHtml = '';

        response.data.forEach(function (e) {
          e.type = 'fb';
        });

        updateNextURL( response.paging.next, 'fb');

        FB.api( rInsta + rToken, function (instaresponse) {

            updateNextURL( instaresponse.paging.next, 'insta');

            instaresponse.data.forEach(function (e) {
              e.updated_time = e.timestamp;
              e.type = 'insta';
              delete e.timestamp;
            });
            // console.log('Instagram feed', instaresponse);

            var socialFeeds = response.data.concat(instaresponse.data);

            socialFeeds.sort(function(a, b) {
              return (a.updated_time < b.updated_time) ? 1 : ((a.updated_time > b.updated_time) ? -1 : 0);
            });
            var socialFeedsHtml = buildFeedHtml(socialFeeds);

            let infScroll = new InfiniteScroll($container, {
              // Infinite Scroll options...
              path: function () {
                return nextpage['fb'];
              },
              responseType: 'text',
              outlayer: msnry,
              history: false,
              status: '.page-load-status',
            });

          // use element to turn HTML string into elements
          var proxyElem = document.createElement('div');

          proxyElem.innerHTML += (socialFeedsHtml);

          // append item elements
          var items = proxyElem.querySelectorAll('.grid__item');

          imagesLoaded( items, function() {
            infScroll.appendItems( items );
            msnry.appended( items );
          });

          infScroll.on('load', function (response, path, items) {

            response =  JSON.parse(response);

            response.data.forEach(function (e) {
              e.type = 'fb';
            });

            updateNextURL( response.paging.next, 'fb');
            // console.log('facebook feed (new)', response);

            fetch(nextpage['insta'], { method: 'GET', redirect: 'follow'})

              .then(iresponse => iresponse.json())
              .then((data) => {
                instaresponse = data;
                // console.log('Instagram feed (new)', instaresponse);

                instaresponse.data.forEach(function (e) {
                  e.updated_time = e.timestamp;
                  e.type = 'insta';
                  delete e.timestamp;
                });

                var socialFeeds = response.data.concat(instaresponse.data);

                socialFeeds.sort(function(a, b) {
                  return (a.updated_time < b.updated_time) ? 1 : ((a.updated_time > b.updated_time) ? -1 : 0);
                });
                // console.log(socialFeeds);
                var socialFeedsHtml = buildFeedHtml(socialFeeds);

                var proxyElem = document.createElement('div');
                proxyElem.innerHTML += (socialFeedsHtml);

                // append item elements
                var items = proxyElem.querySelectorAll('.grid__item');

                updateNextURL( instaresponse.paging.next, 'insta');

                imagesLoaded( items, function() {
                  infScroll.appendItems( items );
                  msnry.appended( items );
                });
              })
          });
          });

      });
    }

    feedsAPIRequest();

  };

}